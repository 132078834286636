import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'


import Layout from '../layouts/default/layout';

import SectionComponent from '../components/section_component'

const isBrowser = typeof window !== "undefined"

const IndexPage = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  const [sectionsForPage] = useState(returnSectionForPage())

  function returnSectionForPage(page = ''){
    let result = []
    Object.keys(dataJSON.app_sections).map(item=>{
     dataJSON.app_sections[item].app_pages.map(item2=>{
       if(item2.primaryDisplay === '/'+page) result.push(dataJSON.app_sections[item])
       return true
     })
     return true
    })

    if(!result.length){

      if(!isBrowser) return result;
      
      if(dataJSON.app_pages.filter(it=>it.page_type=='schedule').length){
        window.location.href =   window.location.origin +  dataJSON.app_pages.filter(it=>it.page_type=='schedule')[0].page_path
      }
      
    }

    return result;
  }




  useEffect(()=>{
    setData(dataJSON)   
     
  }, [sectionsForPage])

  
  return (
    <>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="flex flex-col gap-24 mt-5">
          {
            sectionsForPage.map((data, key)=>{
              return(
                <SectionComponent key={key} data={data}/>
              )
            })
          }
        </div>
      </Layout>

    </>
  )
}

export default IndexPage
